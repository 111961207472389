body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body,
html,
input,
select {
  font-family: 'BasisGrotesquePro', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'PPTelegraf', sans-serif;
}

@media (max-width: 900px) {
  #hubspot-messages-iframe-container.widget-align-right {
    display: none !important;
  }
}

@font-face {
  font-family: 'BasisGrotesquePro';
  src: local('BasisGrotesqueProRegular'),
    url('./fonts/BasisGrotesqueProRegular.otf') format('opentype');
  font-weight: 400 500;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  src: local('BasisGrotesqueProBold'), url('./fonts/BasisGrotesqueProBold.otf') format('opentype');
  font-weight: 600 800;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  src: local('BasisGrotesqueProLight'), url('./fonts/BasisGrotesqueProLight.otf') format('opentype');
  font-weight: 100 300;
}

@font-face {
  font-family: 'PPTelegraf';
  src: local('PPTelegraf'), url('./fonts/PPTelegraf-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'PPTelegraf';
  src: local('PPTelegraf-UltraBold'), url('./fonts/PPTelegraf-UltraBold.otf') format('opentype');
  font-weight: 500 800;
}

@font-face {
  font-family: 'PPTelegraf';
  src: local('PPTelegraf-UltraLight'), url('./fonts/PPTelegraf-UltraLight.otf') format('opentype');
  font-weight: 100 300;
}
